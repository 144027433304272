import React, { memo } from 'react'

import { Grid, Typography } from '@mui/material'

import Logo from 'assets/images/logo/logo.png'
import LogoFull from 'assets/images/logo/logoFull.png'
import useIsBreakpoint from 'hooks/useIsBreakpoint'
import usePadding from 'hooks/usePadding'
import { RootState } from 'store'
import { useAppSelector } from 'store/hooks'

import Asic from '../Asic'
import useStyles from './styles'

const CustomerEarningsCard: React.FC = () => {
  const classes = useStyles()
  const padding = usePadding()
  const { isMobileTablet } = useIsBreakpoint()

  const title = useAppSelector(
    (state: RootState) =>
      state.landing.layout?.customerEarningsTitle?.title ?? '',
  )

  const value = useAppSelector((state: RootState) => state.landing.volume || '')

  const paybackText = useAppSelector(
    (state: RootState) => state.landing.layout?.paybackText?.title ?? '',
  )

  return (
    <Grid pl={padding} pr={padding} mb={isMobileTablet ? 10 : 25} container>
      <Grid
        alignItems="center"
        classes={{ root: classes.container }}
        direction="column"
        pt={6}
        container
      >
        <img
          src={isMobileTablet ? Logo : LogoFull}
          alt="Mining Guru"
          style={{ width: isMobileTablet ? 50 : 300 }}
        />
        <Typography
          variant="h5"
          mt={isMobileTablet ? 3 : 6}
          mb={2}
          textTransform="uppercase"
          textAlign="center"
        >
          {title}
        </Typography>
        <Typography
          variant="h1"
          classes={{ root: classes.value }}
          mb={4}
          textAlign="center"
        >
          {value}
        </Typography>
        <Asic />
        <Grid
          classes={{ root: classes.paybackEllipse }}
          alignItems="center"
          justifyContent="center"
          container
        />
        <Grid
          classes={{ root: classes.paybackContainer }}
          alignItems="center"
          justifyContent="center"
          borderRadius="50%"
          p={3}
          container
        >
          <Typography variant="subtitle1" textAlign="center">
            {paybackText}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(CustomerEarningsCard)
