import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: 32,
    '&.MuiGrid-container': {
      width: 'unset',
    },
  },
  containerText: {
    marginLeft: 12,
    '&.MuiGrid-container': {
      width: 'unset',
    },
  },
  label: {
    '&.MuiTypography-caption': {
      marginRight: theme.spacing(0.5),
    },
  },
}))

export default useStyles
