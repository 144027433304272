import React, { memo } from 'react'

import Marquee from 'react-fast-marquee'

import { Grid } from '@mui/material'

import useIsBreakpoint from 'hooks/useIsBreakpoint'

import { BRANDS } from './constants'
import useStyles from './styles'

const BrandsRunningLine: React.FC = () => {
  const classes = useStyles()
  const { isMobileTablet } = useIsBreakpoint()

  return (
    <Grid mb={isMobileTablet ? 6 : 16} container>
      <Marquee speed={120} gradient={false}>
        {BRANDS.map(({ name, logo: Logo }) => (
          <Grid
            key={name}
            classes={{ root: classes.brand }}
            alignItems="center"
            justifyContent="center"
            container
          >
            <Logo className={classes.logo} />
          </Grid>
        ))}
      </Marquee>
    </Grid>
  )
}

export default memo(BrandsRunningLine)
