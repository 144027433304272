export const SERVER_API = process.env.REACT_APP_SERVER_API_URL
export const SHOP_API = process.env.REACT_APP_SHOP_API_URL

export const ENDPOINTS = {
  example: '/example',
  landing: '/landing',
  news: '/news',
  faq: '/faq',
  feedbacks: '/feedbacks',
  requests: '/requests',
}

export const ENDPOINTS_SHOP = {
  feedbacks: 'reviews',
  news: 'news',
}
