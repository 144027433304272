import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { SERVER_API, ENDPOINTS } from 'constants/Endpoints'

import { BaseResponse } from '../types'
import { LandingLayout, News, Faq } from './types'

export const landingApi = createApi({
  reducerPath: 'landingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_API,
  }),
  endpoints: (builder) => ({
    getLanding: builder.mutation<LandingLayout, void>({
      query: () => ENDPOINTS.landing,
      transformResponse: ({ data }: BaseResponse<LandingLayout>) => data,
    }),
    getNews: builder.mutation<News[], void>({
      query: () => ENDPOINTS.news,
      transformResponse: ({ data }: BaseResponse<News[]>) => data,
    }),
    getFaq: builder.mutation<Faq[], void>({
      query: () => ENDPOINTS.faq,
      transformResponse: ({ data }: BaseResponse<Faq[]>) => data,
    }),
  }),
})

export const { useGetLandingMutation, useGetNewsMutation, useGetFaqMutation } =
  landingApi
