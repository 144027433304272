export const LOGO_SIZE = {
  laptop: {
    width: 300,
  },
  tablet: {
    width: 200,
    height: 25,
  },
  mobile: {
    width: 180,
    height: 24,
  },
}

export const BTN_ICON_SIZE_TABLET = 40
export const BTN_ICON_SIZE_LAPTOP = 45
