import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

import colors from 'config/theme/colors'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: 48,
    borderRadius: 150,
    backgroundColor: colors.backgroundColorThird,
    padding: '0 8px',
    '&.MuiGrid-container': {
      width: 'unset',

      [theme.breakpoints.down('md')]: {
        width: '100%',
        borderRadius: 0,
        margin: 0,
        padding: 0,
        background: 'unset',
        height: 56,
      },

      [theme.breakpoints.down('sm')]: {
        height: 40,
      },
    },
  },
  rate: {
    '&.MuiGrid-container': {
      width: 'unset',

      [theme.breakpoints.down('sm')]: {
        margin: 0,
        width: '50%',

        '&:not(:first-child)': {
          justifyContent: 'flex-end',
        },
      },
    },
    '&:not(:first-child)': {
      paddingLeft: theme.spacing(2),
      borderLeft: `1px solid ${colors.borderColorThirdHalf}`,
    },
  },
}))

export default useStyles
