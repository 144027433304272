import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  feedback: {
    whiteSpace: 'pre-wrap',
  },
  user: {
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },
  userName: {
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${theme.spacing(10)})`,
    },
  },
}))

export default useStyles
