import React, { memo } from 'react'

import { Grid } from '@mui/material'

import ExchangeRate from 'components/ExchangeRate'

import { EXCHANGE_RATES } from './constants'
import useStyles from './styles'
import { ExchangeRatesProps, CustomExchangeRateProps } from './types'

const ExchangeRates: React.FC<ExchangeRatesProps> = ({ rates }) => {
  const classes = useStyles()

  return (
    <Grid
      classes={{ root: classes.container }}
      alignItems="center"
      justifyContent="space-between"
      container
    >
      {EXCHANGE_RATES.map((rate: CustomExchangeRateProps) => {
        const currentRate = rates[rate.name]

        return (
          <Grid
            classes={{ root: classes.rate }}
            mr={2}
            key={rate.name}
            container
          >
            <ExchangeRate
              label={rate.label}
              logo={rate.logo}
              value={currentRate?.value ?? ''}
              dropped={currentRate?.dropped}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default memo(ExchangeRates)
