import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

import { PADDING, PADDING_MOBILE, PADDING_TABLET } from 'constants/Layout'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
  },
  title: {
    position: 'absolute',
    top: 0,
    left: theme.spacing(PADDING),
    [theme.breakpoints.down('md')]: {
      left: theme.spacing(PADDING_TABLET),
      top: '-5px',
    },
    [theme.breakpoints.down('sm')]: {
      left: theme.spacing(PADDING_MOBILE),
    },
  },
}))

export default useStyles
