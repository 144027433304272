import React, { memo } from 'react'

import { Grid, Typography } from '@mui/material'

import useIsBreakpoint from 'hooks/useIsBreakpoint'
import usePadding from 'hooks/usePadding'
import { RootState } from 'store'
import { useAppSelector } from 'store/hooks'

import useStyles from './styles'

const Heading: React.FC = () => {
  const classes = useStyles()
  const padding = usePadding()
  const { isMobileTablet, isMobile, isTablet } = useIsBreakpoint()

  const paddingTopMobileTabletContainer = isTablet ? 23 : 16
  const paddingBottomMobileTabletContainer = isTablet ? 5 : 2

  const headingTitle = useAppSelector(
    (state: RootState) => state.landing.layout?.main?.title ?? '',
  )

  const headingDescription = useAppSelector(
    (state: RootState) => state.landing.layout?.main?.description ?? [],
  )

  return (
    <Grid id="heading" container>
      {!isMobile && <canvas className={classes.canvas} />}
      <Grid
        pl={padding}
        pr={padding}
        pt={isMobileTablet ? paddingTopMobileTabletContainer : 28}
        pb={isMobileTablet ? paddingBottomMobileTabletContainer : 27}
        container
      >
        <Grid
          classes={{ root: classes.textContainer }}
          direction="column"
          container
        >
          <Typography
            mb={isMobileTablet ? 2 : 4}
            variant={isMobileTablet ? 'h2' : 'h1'}
            classes={{ root: classes.title }}
          >
            {headingTitle}
          </Typography>
          {headingDescription.map(({ text, key }) => (
            <Typography
              key={key}
              variant="body1"
              classes={{ root: classes.description }}
            >
              {text}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(Heading)
