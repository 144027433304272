import React from 'react'

import { Routes, Route, Navigate } from 'react-router-dom'

import mapRoutes from './mapRoutes'
import routes from './routes'

const RouterMap: React.FC = () => (
  <Routes>
    {mapRoutes(routes)}
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
)

export default RouterMap
