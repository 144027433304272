import React, { memo } from 'react'

import classNames from 'classnames'

import { Box, Button, CircularProgress } from '@mui/material'

import useStyles from './styles'
import { CustomButtonProps } from './types'

const BaseButton = ({
  label,
  classesRoot,
  variant = 'contained',
  disabled,
  ...rest
}: CustomButtonProps) => {
  const classes = useStyles()

  return (
    <Button
      {...rest}
      classes={{
        root: classNames(classes.button, classesRoot),
      }}
      variant={variant}
      disabled={disabled}
    >
      {label}
    </Button>
  )
}

const CustomButton = ({
  isLoading,
  withLoading,
  ...rest
}: CustomButtonProps): React.ReactElement => {
  const classes = useStyles()

  if (withLoading) {
    return (
      <Box sx={{ position: 'relative' }}>
        {isLoading && (
          <CircularProgress size={32} classes={{ root: classes.loader }} />
        )}
        <BaseButton {...rest} />
      </Box>
    )
  }

  return <BaseButton {...rest} />
}

export default memo(CustomButton)
