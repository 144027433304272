import { useEffect, useState } from 'react'

const useTimerSeconds = () => {
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(setSeconds, 1000, seconds - 1)
    }
  }, [seconds])

  return { seconds, setSeconds }
}

export default useTimerSeconds
