import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { SHOP_API, ENDPOINTS_SHOP } from 'constants/Endpoints'

import { Feedback } from '../landingApi/types'
import { Rewiew } from './types'

export const feedbacksApi = createApi({
  reducerPath: 'feedbacksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SHOP_API,
  }),
  endpoints: (builder) => ({
    getFeedbacks: builder.mutation<Feedback[], void>({
      query: () => `/index.php?route=api/${ENDPOINTS_SHOP.feedbacks}&limit=30`,
      transformResponse: ({ reviews }: { reviews: Rewiew[] }) =>
        reviews.map(({ customer, text, review_id }) => ({
          text,
          id: review_id,
          client: { name: customer, surname: '', avatar: '' },
        })) as Feedback[],
    }),
  }),
})

export const { useGetFeedbacksMutation } = feedbacksApi
