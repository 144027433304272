import React, { memo } from 'react'

import { Grid, Typography } from '@mui/material'

import useIsBreakpoint from 'hooks/useIsBreakpoint'

import useStyles from './styles'
import { ExchangeRateProps } from './types'

const ICON_SIZE_MOBILE = 24
const ICON_SIZE_TABLET = 32

const ExchangeRate: React.FC<ExchangeRateProps> = ({
  logo: Logo,
  label,
  value,
  dropped,
}) => {
  const classes = useStyles()
  const { isMobile, isTablet } = useIsBreakpoint()

  return (
    <Grid
      classes={{ root: classes.container }}
      alignItems="center"
      justifyContent="space-between"
      container
    >
      <Logo
        {...{
          ...(isMobile && {
            width: ICON_SIZE_MOBILE,
            height: ICON_SIZE_MOBILE,
          }),
          ...(isTablet && {
            width: ICON_SIZE_TABLET,
            height: ICON_SIZE_TABLET,
          }),
        }}
      />
      <Grid classes={{ root: classes.containerText }} container>
        <Typography
          variant="caption"
          classes={{ root: classes.label }}
          textTransform="uppercase"
        >
          {label}
        </Typography>
        <Typography
          variant="caption"
          textTransform="uppercase"
          color={`${dropped ? 'error' : 'success'}.main`}
        >
          {`$ ${value}`}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default memo(ExchangeRate)
