import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  title: {
    '&.MuiTypography-root': {
      letterSpacing: '-1px',
    },
  },
  textContainer: {
    maxWidth: 864,
  },
  description: {
    maxWidth: 752,
  },
  canvas: {
    position: 'absolute',
  },
}))

export default useStyles
