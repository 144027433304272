import { ReactComponent as Amd } from 'assets/images/logo/amd.svg'
import { ReactComponent as Asrock } from 'assets/images/logo/asrock.svg'
import { ReactComponent as Asus } from 'assets/images/logo/asus.svg'
import { ReactComponent as Bitmain } from 'assets/images/logo/bitmain.svg'
import { ReactComponent as Evga } from 'assets/images/logo/evga.svg'
import { ReactComponent as Gigabyte } from 'assets/images/logo/gigabyte.svg'
import { ReactComponent as Intel } from 'assets/images/logo/intel.svg'
import { ReactComponent as Msi } from 'assets/images/logo/msi.svg'
import { ReactComponent as Nvidia } from 'assets/images/logo/nvidia.svg'
import { ReactComponent as Palit } from 'assets/images/logo/palit.svg'
import { ReactComponent as Zotac } from 'assets/images/logo/zotac.svg'

export const BRANDS = [
  {
    name: 'amd',
    logo: Amd,
  },
  {
    name: 'asrock',
    logo: Asrock,
  },
  {
    name: 'asus',
    logo: Asus,
  },
  {
    name: 'bitmain',
    logo: Bitmain,
  },
  {
    name: 'evga',
    logo: Evga,
  },
  {
    name: 'gigabyte',
    logo: Gigabyte,
  },
  {
    name: 'intel',
    logo: Intel,
  },
  {
    name: 'msi',
    logo: Msi,
  },
  {
    name: 'nvidia',
    logo: Nvidia,
  },
  {
    name: 'palit',
    logo: Palit,
  },
  {
    name: 'zotac',
    logo: Zotac,
  },
]
