import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

import colors from 'config/theme/colors'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: 88,
    background: 'rgba(255, 255, 255, 0.02)',
    backdropFilter: 'blur(86.8px)',
    position: 'fixed',
    zIndex: 1000,
  },
  content: {
    maxWidth: 1440,
  },
  leftContainer: {
    [theme.breakpoints.down('md')]: {
      height: 56,
      width: '100%',
      borderBottom: `1px solid ${colors.borderColorThirdHalf}`,
    },
  },
  rightContainer: {
    '&.MuiGrid-container': {
      width: 'unset',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        alignSelf: 'flex-end',
      },
      [theme.breakpoints.down('md')]: {
        alignSelf: 'flex-start',
      },
    },
  },
  btnGroup: {
    marginLeft: theme.spacing(3),
  },
  btnCallBack: {
    width: 230,
  },
  btnTelegram: {
    width: 140,
    '&.MuiButtonBase-root': {
      marginLeft: theme.spacing(3),
    },
  },
  smallBtnTelegram: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 12,
    },
  },
}))

export default useStyles
