import { ReactComponent as Asic } from 'assets/images/asic.svg'
import { ReactComponent as VideoCard } from 'assets/images/videoCard.svg'
import { LandingSubType } from 'store/services/landingApi/types'

export const CARDS = [
  {
    name: LandingSubType.BTN_FIRST,
    logo: VideoCard,
  },
  {
    name: LandingSubType.BTN_SECOND,
    logo: Asic,
  },
]
