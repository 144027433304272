import { PADDING, PADDING_MOBILE, PADDING_TABLET } from 'constants/Layout'

import useIsBreakpoint from './useIsBreakpoint'

const usePadding = () => {
  const { isMobileTablet, isTablet } = useIsBreakpoint()
  const paddingMobileTablet = isTablet ? PADDING_TABLET : PADDING_MOBILE
  const padding = isMobileTablet ? paddingMobileTablet : PADDING

  return padding
}

export default usePadding
