import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { SERVER_API, ENDPOINTS } from 'constants/Endpoints'

import { News } from '../landingApi/types'
import { BaseResponse } from '../types'

export const newsApi = createApi({
  reducerPath: 'newsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_API,
  }),
  endpoints: (builder) => ({
    getNews: builder.mutation<News, string>({
      query: (url: string) => `${ENDPOINTS.news}/${url}`,
      transformResponse: ({ data }: BaseResponse<News>) => data,
    }),
  }),
})

export const { useGetNewsMutation } = newsApi
