import React, { memo } from 'react'

import useIsBreakpoint from 'hooks/useIsBreakpoint'
import { RootState } from 'store'
import { useAppSelector } from 'store/hooks'

import CarouselContent from '../CarouselContent'
import FeedbackCard from '../FeedbackCard'

const Feedback: React.FC = () => {
  const { isMobileTablet } = useIsBreakpoint()

  const feedbacks = useAppSelector(
    (state: RootState) => state.landing.feedbacks,
  )

  if (!feedbacks.length) return null

  return (
    <CarouselContent title="Отзывы" marginBottom={isMobileTablet ? 10 : 23}>
      {feedbacks.map(({ id, client, text }) => (
        <FeedbackCard key={id} user={client} feedback={text} />
      ))}
    </CarouselContent>
  )
}

export default memo(Feedback)
