import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    maxWidth: 528,
    [theme.breakpoints.down('lg')]: {
      maxWidth: 'unset',
    },
  },
  subDescription: {
    maxWidth: 640,
    [theme.breakpoints.down('lg')]: {
      maxWidth: 'unset',
    },
  },
}))

export default useStyles
