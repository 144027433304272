import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

import colors from 'config/theme/colors'

const IMAGE_HEIGHT = 600
const IMAGE_HEIGHT_TABLET = 350
const IMAGE_HEIGHT_MOBILE = 230

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '&.MuiContainer-root': {
      backgroundColor: colors.backgroundColorPrimary,
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  wrapper: {
    '&.MuiContainer-root': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  content: {
    '&.MuiGrid-root': {
      maxWidth: 1440,
    },
  },
  img: {
    height: IMAGE_HEIGHT,
    objectFit: 'fill',
    width: '70%',
    marginBottom: theme.spacing(8),

    [theme.breakpoints.down('md')]: {
      height: IMAGE_HEIGHT_TABLET,
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: IMAGE_HEIGHT_MOBILE,
    },
  },
  loaderWrapper: {
    alignItems: 'center',
  },
}))

export default useStyles
