export const CARD_ICON_SIZE = {
  mobileTablet: {
    width: 148,
    height: 90,
  },
  laptop: {
    width: 250,
    height: 150,
  },
}
