import { BaseDocumentType } from '../types'

export enum LandingType {
  MAIN = 'main',
  STORE_BTNS = 'storeBtns',
  COMPANY_INFO = 'companyInfo',
  PRIVACY_POLICY = 'privacyPolicy',
  REQUEST_BOX = 'requestBox',
  CONTACT = 'contact',
  CUSTOMER_EARNINGS_TITLE = 'customerEarningsTitle',
  PAYBACK_TEXT = 'paybackText',
}

export enum LandingSubType {
  BTN_FIRST = 'btnFirst',
  BTN_SECOND = 'btnSecond',
  PHONE = 'phone',
  EMAIL = 'email',
  TELEGRAM = 'telegram',
  TELEGRAM_BTN = 'telegramBtn',
  WHATSAPP = 'whatsapp',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
  VK = 'vk',
}

export type DescriptionItem = {
  key: string
  text: string
}

export type LandingLayoutItem = {
  id: string
  title: string
  description: DescriptionItem[]
  type: LandingType
  subType: LandingSubType
  subDescription: DescriptionItem[]
  link: string
  createdAt: string
  updatedAt: string
}

export type InnerLandingLayoutItem = {
  [keys: string]: LandingLayoutItem
}

export type LandingLayout = {
  [LandingType.MAIN]?: LandingLayoutItem
  [LandingType.STORE_BTNS]?: InnerLandingLayoutItem
  [LandingType.COMPANY_INFO]?: LandingLayoutItem
  [LandingType.PRIVACY_POLICY]?: LandingLayoutItem
  [LandingType.REQUEST_BOX]?: LandingLayoutItem
  [LandingType.CONTACT]?: InnerLandingLayoutItem
  [LandingType.CUSTOMER_EARNINGS_TITLE]?: LandingLayoutItem
  [LandingType.PAYBACK_TEXT]?: LandingLayoutItem
}

export interface Client extends BaseDocumentType {
  name: string
  surname: string
  avatar: string
}

export interface Feedback extends BaseDocumentType {
  text: string
  client: Client
}

export interface News extends BaseDocumentType {
  title: string
  link: string
  linkImage: string
  description?: string[]
  isCustom?: boolean
  url?: string
}

export interface Faq extends BaseDocumentType {
  title: string
  description: DescriptionItem[]
}
