import { useEffect } from 'react'

import { io } from 'socket.io-client'

import { SERVER_API } from 'constants/Endpoints'
import { useAppDispatch } from 'store/hooks'
import { setRates } from 'store/reducers/landing'
import { Crypto } from 'store/reducers/landing/types'

const socket = io(SERVER_API!, { transports: ['websocket'] })

const useSocket = () => {
  const dispatch = useAppDispatch()

  const getCrypto = (message: Crypto) => {
    dispatch(setRates(message))
  }

  useEffect(() => {
    socket.on('crypto:get', getCrypto)

    return () => {
      socket.off('crypto:get')
    }
  }, [])
}

export default useSocket
