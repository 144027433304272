import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  LandingLayout,
  Feedback,
  News,
  Faq,
} from 'store/services/landingApi/types'

import { LandingState, Crypto } from './types'

const initialState: LandingState = {
  layout: {},
  news: [],
  faq: [],
  feedbacks: [],
  volume: '',
  rates: {},
}

const landingSlice = createSlice({
  name: 'landing',
  initialState,
  reducers: {
    app: () => initialState,
    setLayout(state, action: PayloadAction<LandingLayout>) {
      state.layout = action.payload
    },
    setNews(state, action: PayloadAction<News[]>) {
      state.news = action.payload
    },
    setFaq(state, action: PayloadAction<Faq[]>) {
      state.faq = action.payload
    },
    setFeedbacks(state, action: PayloadAction<Feedback[]>) {
      state.feedbacks = action.payload
    },
    setRates(state, action: PayloadAction<Crypto>) {
      const { volume, cryptocurrensies } = action.payload
      state.volume = volume
      state.rates = cryptocurrensies
    },
  },
})

export const { app, setLayout, setNews, setFaq, setFeedbacks, setRates } =
  landingSlice.actions

export default landingSlice.reducer
