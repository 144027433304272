import React, { memo, useState, useEffect } from 'react'

import classNames from 'classnames'
import { useElementSize } from 'usehooks-ts'

import { Grid } from '@mui/material'

import { ReactComponent as ReadMoreIcon } from 'assets/icons/readMore.svg'

import Button from '../Button'
import useStyles from './styles'
import { ReadMoreProps } from './types'

const ReadMore: React.FC<ReadMoreProps> = ({
  children,
  maxHeight = 340,
  hideLabel = 'Свернуть',
  showLabel = 'Читать далее',
  initialState = false,
}) => {
  const classes = useStyles()

  const [show, setShowState] = useState(initialState)
  const [spoiler, setSpoilerState] = useState(initialState)
  const [contentRef, { height }] = useElementSize()

  const spoilerMoreContent = show ? hideLabel : showLabel

  useEffect(() => {
    if (maxHeight !== undefined) setSpoilerState(maxHeight < height)
  }, [height, maxHeight, children])

  const onClickSpoiler = () => setShowState((opened) => !opened)

  return (
    <Grid>
      <Grid
        direction="column"
        className={classes.content}
        style={{
          maxHeight: !show ? maxHeight : height || undefined,
        }}
        container
      >
        <div ref={contentRef}>{children}</div>
      </Grid>
      {spoiler && (
        <Button
          onClick={onClickSpoiler}
          variant="text"
          className={classNames(show && classes.readLessBtn)}
          label={spoilerMoreContent}
          endIcon={<ReadMoreIcon />}
        />
      )}
    </Grid>
  )
}

export default memo(ReadMore)
