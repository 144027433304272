import React, { memo } from 'react'

import { Avatar } from '@mui/material'

import useIsBreakpoint from 'hooks/useIsBreakpoint'

import { AvatarProps } from './types'

const getFirstLetterUppercase = (str: string) => str.charAt(0).toUpperCase()

const SIZE_AVATAR = 74
const SIZE_AVATAR_MOBILE = 64

const CustomAvatar: React.FC<AvatarProps> = ({ user }) => {
  const letters = `${getFirstLetterUppercase(
    user.name,
  )}${getFirstLetterUppercase(user.surname)}`
  const { isMobileTablet } = useIsBreakpoint()
  const size = isMobileTablet ? SIZE_AVATAR_MOBILE : SIZE_AVATAR
  const sx = { width: size, height: size }

  if (user.avatar) {
    return (
      <Avatar alt={`${user.name} ${user.surname}`} src={user.avatar} sx={sx} />
    )
  }

  return <Avatar sx={sx}>{letters}</Avatar>
}

export default memo(CustomAvatar)
