import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  '@keyframes rotate': {
    to: {
      transform: 'rotate(360deg)',
    },
  },
  container: {
    position: 'relative',
    overflow: 'hidden',
  },
  asic: {
    maxWidth: 842,

    [theme.breakpoints.down('md')]: {
      maxWidth: 274,
    },
  },
  wheel: {
    animation: '$rotate 3s infinite linear',
    position: 'absolute',
    left: 'calc(50% - 140px)',
    bottom: '-168px',

    [theme.breakpoints.down('md')]: {
      left: 'calc(50% - 45px)',
      bottom: '14px',
    },
  },
}))

export default useStyles
