import React from 'react'

import classNames from 'classnames'
import { ArrowProps } from 'react-multi-carousel'

import { ReactComponent as ArrowLeft } from 'assets/icons/arrowLeft.svg'
import { ReactComponent as ArrowLeftMobile } from 'assets/icons/arrowLeftMobile.svg'
import { ReactComponent as ArrowRight } from 'assets/icons/arrowRight.svg'
import { ReactComponent as ArrowRightMobile } from 'assets/icons/arrowRightMobile.svg'
import useIsBreakpoint from 'hooks/useIsBreakpoint'

import useStyles from './styles'
import { CustomArrowProps, WrapperArrowProps } from './types'

const CustomArrow = ({ onClick, className, children }: CustomArrowProps) => (
  <button type="button" onClick={onClick} className={className}>
    {children}
  </button>
)

const WrapperArrow = ({
  onClick,
  arrowDesktop: ArrowDesktop,
  arrowMobile: ArrowMobile,
  className,
}: WrapperArrowProps) => {
  const classes = useStyles()
  const { isMobileTablet } = useIsBreakpoint()

  const Arrow = isMobileTablet ? ArrowMobile : ArrowDesktop

  return (
    <CustomArrow
      onClick={onClick}
      className={classNames(classes.arrow, classes[className])}
    >
      <Arrow />
    </CustomArrow>
  )
}

export const CustomLeftArrow = ({ onClick }: ArrowProps) => (
  <WrapperArrow
    onClick={onClick}
    className="arrowLeft"
    arrowDesktop={ArrowLeft}
    arrowMobile={ArrowLeftMobile}
  />
)

export const CustomRightArrow = ({ onClick }: ArrowProps) => (
  <WrapperArrow
    onClick={onClick}
    className="arrowRight"
    arrowDesktop={ArrowRight}
    arrowMobile={ArrowRightMobile}
  />
)
