import React, { memo } from 'react'

import { ReactComponent as AsicBig } from 'assets/images/asic/asicBig.svg'
import { ReactComponent as AsicSmall } from 'assets/images/asic/asicSmall.svg'
import { ReactComponent as AsicWheel } from 'assets/images/asic/asicWheel.svg'
import useIsBreakpoint from 'hooks/useIsBreakpoint'

import useStyles from './styles'

const WHEEL_SIZE = 300
const WHEEL_SIZE_MOBILE = 98

const Asic: React.FC = () => {
  const classes = useStyles()
  const { isMobileTablet } = useIsBreakpoint()

  const AsicFull = isMobileTablet ? AsicSmall : AsicBig
  const wheelSize = isMobileTablet ? WHEEL_SIZE_MOBILE : WHEEL_SIZE

  return (
    <div className={classes.container}>
      <AsicFull className={classes.asic} />
      <div className={classes.wheel}>
        <AsicWheel width={wheelSize} height={wheelSize} />
      </div>
    </div>
  )
}

export default memo(Asic)
