import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  content: {
    overflow: 'hidden',
    transitionProperty: 'max-height',
    transitionTimingFunction: 'ease',
    transitionDuration: '200ms',
  },
  readLessBtn: {
    '& .MuiButton-endIcon': {
      transform: 'rotate(180deg)',
    },
  },
}))

export default useStyles
