import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const useIsBreakpoint = () => {
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const isMobileTablet = useMediaQuery(theme.breakpoints.down('md'))

  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const isLaptop = useMediaQuery(theme.breakpoints.between('md', 'lg'))

  return { isMobile, isTablet, isMobileTablet, isLaptop }
}

export default useIsBreakpoint
