import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  },
  container: {
    minHeight: 40,
    marginTop: theme.spacing(6),
    '&.MuiGrid-root': {
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        marginTop: theme.spacing(3),
        flexDirection: 'column',
      },
    },
  },
  text: {
    '&.MuiTypography-root': {
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        lineHeight: '24px',
      },
    },
  },
}))

export default useStyles
