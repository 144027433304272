import { useState } from 'react'

import { AlertColor } from '@mui/material'

import RequestMessages from 'constants/RequestMessages'

const useAlert = () => {
  const [openAleft, setOpenAlert] = useState<boolean>(false)
  const [severityAleft, setSeverityAlert] = useState<AlertColor>('success')
  const [messageAlert, setMessageAlert] = useState('')

  const showAlert = (block: string, severity: AlertColor) => {
    setMessageAlert(RequestMessages[block][severity])
    setSeverityAlert(severity)
    setOpenAlert(true)
  }

  const handleCloseAlert = () => setOpenAlert(false)

  return { openAleft, severityAleft, messageAlert, showAlert, handleCloseAlert }
}

export default useAlert
