import React from 'react'

import useSocket from 'hooks/useSocket'
import RouterMap from 'router'

const App: React.FC = () => {
  useSocket()

  return <RouterMap />
}

export default App
