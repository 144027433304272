import React, { memo } from 'react'

import { Grid } from '@mui/material'

import { ReactComponent as Call } from 'assets/icons/call.svg'
import { ReactComponent as TelegramIcon } from 'assets/icons/telegramBtn.svg'
import useIsBreakpoint from 'hooks/useIsBreakpoint'

import { BTN_ICON_SIZE_TABLET, BTN_ICON_SIZE_LAPTOP } from './contants'
import useStyles from './styles'
import { CommunicationBtnsProps } from './types'

const TELEGRAM_LINK = 'https://t.me/javascript_jobs'

const CommunicationBtns: React.FC<CommunicationBtnsProps> = ({
  handleOnCall,
  hideCall,
}) => {
  const classes = useStyles()

  const { isTablet, isLaptop } = useIsBreakpoint()

  const sizeBtnIcon = {
    ...(isTablet && {
      width: BTN_ICON_SIZE_TABLET,
      height: BTN_ICON_SIZE_TABLET,
    }),
    ...(isLaptop && {
      width: BTN_ICON_SIZE_LAPTOP,
      height: BTN_ICON_SIZE_LAPTOP,
    }),
  }

  return (
    <Grid>
      {!hideCall && <Call onClick={handleOnCall} {...sizeBtnIcon} />}
      <a
        href={TELEGRAM_LINK}
        target="_blank"
        rel="noreferrer"
        className={classes.smallBtnTelegram}
      >
        <TelegramIcon {...sizeBtnIcon} />
      </a>
    </Grid>
  )
}

export default memo(CommunicationBtns)
