import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { SHOP_API, ENDPOINTS_SHOP } from 'constants/Endpoints'

import { News } from '../landingApi/types'
import { Article } from './types'

export const newsShopApi = createApi({
  reducerPath: 'newsShopApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SHOP_API,
  }),
  endpoints: (builder) => ({
    getShopNews: builder.mutation<News[], void>({
      query: () =>
        `/index.php?route=api/${ENDPOINTS_SHOP.news}&limit=20&width=200&height=200`,
      transformResponse: ({ articles }: { articles: Article[] }) =>
        articles.map(({ name, article_id, date_added, link, image }) => {
          const splittedDate = date_added?.split('.') ?? []
          const date = splittedDate.length
            ? new Date(
                `${splittedDate[1]}.${splittedDate[0]}.${splittedDate[2]}`,
              ).toDateString()
            : ''

          return {
            link: link.replace('&amp;', '&'),
            id: article_id,
            linkImage: image,
            title: name,
            isCustom: false,
            createdAt: date,
            updatedAt: date,
          }
        }) as News[],
    }),
  }),
})

export const { useGetShopNewsMutation } = newsShopApi
