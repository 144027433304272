import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 280,
    '&.MuiGrid-root': {
      width: '25%',

      [theme.breakpoints.down('lg')]: {
        width: '50%',
        flexDirection: 'row',
        maxWidth: 'unset',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginBottom: theme.spacing(4),
      },

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  label: {
    whiteSpace: 'pre-wrap',
    '&.MuiTypography-root': {
      fontWeight: 500,
      fontFamily: 'Montserrat Medium',

      [theme.breakpoints.down('lg')]: {
        maxWidth: 233,
      },

      [theme.breakpoints.between('md', 'lg')]: {
        maxWidth: 240,
      },
    },
  },
}))

export default useStyles
