import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

import colors from 'config/theme/colors'

const useStyles = makeStyles((theme: Theme) => ({
  '@keyframes rotate': {
    to: {
      transform: 'rotate(360deg)',
    },
  },
  container: {
    backgroundColor: colors.backgroundColorSecondary,
    borderRadius: theme.spacing(theme.breakpoints.down('md') ? 3 : 6),
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(9),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  value: {
    '&.MuiTypography-root': {
      color: colors.textColorSecondary,
    },
  },
  test: {
    animation: '$rotate 2s infinite linear',
  },
  paybackEllipse: {
    '&.MuiGrid-root': {
      width: 398,
    },
    position: 'absolute',
    right: 36,
    bottom: 44,
    height: 398,
    backgroundColor: colors.accentColorGreen,
    opacity: 0.2,
    filter: 'blur(245.6px)',
    [theme.breakpoints.down('md')]: {
      '&.MuiGrid-root': {
        width: 194,
        height: 194,
        right: 0,
        bottom: 0,
        opacity: 0.4,
      },
    },
  },
  paybackContainer: {
    '&.MuiGrid-root': {
      width: 214,
    },
    height: 214,
    backgroundColor: colors.accentColorGreen,
    position: 'absolute',
    right: 127,
    bottom: 115,
    [theme.breakpoints.down('md')]: {
      '&.MuiGrid-root': {
        width: 144,
        height: 144,
        right: 32,
        bottom: 32,
      },
    },
  },
}))

export default useStyles
