import { makeStyles } from '@mui/styles'

import colors from 'config/theme/colors'

const useStyles = makeStyles(() => ({
  container: {
    '&.MuiContainer-root': {
      backgroundColor: colors.backgroundColorPrimary,
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  telegramLink: {
    width: 'fit-content',
  },
}))

export default useStyles
