import React, { memo } from 'react'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { CustomLeftArrow, CustomRightArrow } from './Arrows'
import useStyles from './styles'
import { CustomCarouselProps } from './types'

const CustomCarousel: React.FC<CustomCarouselProps> = ({ children }) => {
  const classes = useStyles()

  return (
    <Carousel
      containerClass={classes.container}
      customLeftArrow={<CustomLeftArrow />}
      customRightArrow={<CustomRightArrow />}
      minimumTouchDrag={80}
      responsive={{
        xl: {
          breakpoint: {
            min: 3001,
            max: 9999,
          },
          items: 3,
          partialVisibilityGutter: 0,
        },
        lg: {
          breakpoint: {
            max: 3000,
            min: 1200,
          },
          items: 3,
          partialVisibilityGutter: 0,
        },
        md: {
          breakpoint: {
            max: 1200,
            min: 600,
          },
          items: 2,
          partialVisibilityGutter: 0,
        },
        mobile: {
          breakpoint: {
            max: 600,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 0,
        },
      }}
      showDots={false}
      slidesToSlide={1}
      infinite
    >
      {children}
    </Carousel>
  )
}

export default memo(CustomCarousel)
