import React, { memo, useEffect, useState } from 'react'

import classNames from 'classnames'
import moment from 'moment'
import { useParams, useNavigate } from 'react-router-dom'

import 'moment/locale/ru'

import { CircularProgress, Container, Grid, Typography } from '@mui/material'

import { Header, AlertMessage } from 'components'
import { FORMAT_DATE } from 'constants/Date'
import {
  SIZE_CIRCULAR_LOADER,
  SIZE_CIRCULAR_LOADER_MOBILE,
} from 'constants/Layout'
import useAlert from 'hooks/useAlert'
import useIsBreakpoint from 'hooks/useIsBreakpoint'
import usePadding from 'hooks/usePadding'
import { ROUTES } from 'router/constants'
import { RootState } from 'store'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setNews } from 'store/reducers/news'
import { News as NewsType } from 'store/services/landingApi/types'
import { useGetNewsMutation } from 'store/services/newsApi'
import { BaseCommonResponse } from 'store/services/types'

import useStyles from './styles'

const News: React.FC = () => {
  const { url } = useParams()
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const padding = usePadding()
  const { isMobileTablet } = useIsBreakpoint()

  const [isLoading, setIsLoading] = useState(true)

  const {
    openAleft,
    severityAleft,
    messageAlert,
    showAlert,
    handleCloseAlert,
  } = useAlert()

  const sizeLoader = isMobileTablet
    ? SIZE_CIRCULAR_LOADER_MOBILE
    : SIZE_CIRCULAR_LOADER

  const [getNews, { isLoading: isLoadingNews }] = useGetNewsMutation()

  const currentNews = useAppSelector(
    (state: RootState) => state.news.currentNews,
  )

  const getNewsData = async (urlNews: string) => {
    try {
      setIsLoading(true)
      const { data: news, error: errorNews }: BaseCommonResponse<NewsType> =
        await getNews(urlNews)

      if (errorNews) throw errorNews
      if (news) dispatch(setNews(news))
    } catch (e: any) {
      if (e.status === 404) {
        navigate(ROUTES.notFound)
        return
      }
      showAlert('newsLoading', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (url) getNewsData(url)
  }, [url])

  return (
    <Container
      maxWidth={false}
      classes={{
        root: classNames(
          classes.container,
          isLoadingNews && classes.loaderWrapper,
        ),
      }}
      disableGutters
    >
      {isLoadingNews || isLoading ? (
        <CircularProgress size={sizeLoader} />
      ) : (
        <Container
          maxWidth={false}
          classes={{ root: classes.wrapper }}
          disableGutters
        >
          <Header hideCall />
          <Grid
            direction="column"
            classes={{ root: classes.content }}
            pl={padding}
            pr={padding}
            pb={10}
            container
          >
            <Grid direction="column" alignItems="center" container>
              <Grid
                pt={isMobileTablet ? 18 : 20}
                pb={isMobileTablet ? 4 : 10}
                direction="column"
                container
              >
                <Typography
                  mb={isMobileTablet ? 4 : 6}
                  variant={isMobileTablet ? 'h2' : 'h1'}
                >
                  {currentNews?.title}
                </Typography>
                <Typography mb={isMobileTablet ? 2 : 4} variant="body2">
                  {moment(currentNews?.createdAt).format(FORMAT_DATE)}
                </Typography>
              </Grid>
              <img
                src={currentNews?.linkImage}
                alt={currentNews?.title}
                className={classes.img}
              />
              <Grid direction="column" container>
                {currentNews?.description?.map((text, index) => (
                  <Typography
                    key={index}
                    variant="body1"
                    mb={isMobileTablet ? 2 : 4}
                  >
                    {text}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
      <AlertMessage
        open={openAleft}
        message={messageAlert}
        severity={severityAleft}
        handleClose={handleCloseAlert}
      />
    </Container>
  )
}

export default memo(News)
