import React, { memo, useEffect, useRef, useState } from 'react'

import { CircularProgress, Container, Grid } from '@mui/material'

import {
  BrandsRunningLine,
  ContactForm,
  CompanyInfo,
  CustomerEarningsCard,
  Feedback,
  Footer,
  Faq,
  Header,
  Heading,
  News,
  ShortInfoPoints,
  StoreCards,
} from 'components'
import {
  SIZE_CIRCULAR_LOADER,
  SIZE_CIRCULAR_LOADER_MOBILE,
} from 'constants/Layout'
import useIsBreakpoint from 'hooks/useIsBreakpoint'
import { useAppDispatch } from 'store/hooks'
import {
  setLayout,
  setNews,
  setFaq,
  setFeedbacks,
} from 'store/reducers/landing'
import { useGetFeedbacksMutation } from 'store/services/feedbacksApi'
import {
  useGetLandingMutation,
  useGetFaqMutation,
  useGetNewsMutation,
} from 'store/services/landingApi'
import {
  LandingLayout,
  News as NewsType,
  Faq as FaqType,
  Feedback as FeedbackType,
} from 'store/services/landingApi/types'
import { useGetShopNewsMutation } from 'store/services/newsShopApi'
import { BaseCommonResponse } from 'store/services/types'
import drawConstellation from 'utils/drawConstellation'

import useStyles from './styles'

const Landing: React.FC = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { isMobileTablet } = useIsBreakpoint()

  const sizeLoader = isMobileTablet
    ? SIZE_CIRCULAR_LOADER_MOBILE
    : SIZE_CIRCULAR_LOADER

  const contactFormRef = useRef<HTMLDivElement | null>(null)

  const [isLoading, setIsloading] = useState(true)

  const [getLandingInfo, { isLoading: isLoadingLayout }] =
    useGetLandingMutation()
  const [getNews, { isLoading: isLoadingNews }] = useGetNewsMutation()
  const [getShopNews, { isLoading: isLoadingShopNews }] =
    useGetShopNewsMutation()
  const [getFaq, { isLoading: isLoadingFaq }] = useGetFaqMutation()
  const [getFeedbacks, { isLoading: isLoadingFeedbacks }] =
    useGetFeedbacksMutation()

  const isCommonLoading =
    isLoading ||
    isLoadingLayout ||
    isLoadingNews ||
    isLoadingShopNews ||
    isLoadingFaq ||
    isLoadingFeedbacks

  const getLanding = async () => {
    try {
      setIsloading(true)
      const {
        data: dataLayout,
        error: errorLayout,
      }: BaseCommonResponse<LandingLayout> = await getLandingInfo()
      if (dataLayout) dispatch(setLayout(dataLayout))

      const { data: news, error: errorNews }: BaseCommonResponse<NewsType[]> =
        await getNews()
      const {
        data: newsShop,
        error: errorShopNews,
      }: BaseCommonResponse<NewsType[]> = await getShopNews()

      if (news || newsShop) {
        const compareNews = [...(news ?? []), ...(newsShop ?? [])]
        const sortedNews = compareNews.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        )

        dispatch(setNews(sortedNews))
      }

      const {
        data: feedbacks,
        error: errorFeedbacks,
      }: BaseCommonResponse<FeedbackType[]> = await getFeedbacks()
      if (feedbacks) dispatch(setFeedbacks(feedbacks))

      const { data: faq, error: errorFaq }: BaseCommonResponse<FaqType[]> =
        await getFaq()
      if (faq) dispatch(setFaq(faq))

      if (errorLayout) throw errorLayout
      if (errorNews) throw errorNews
      if (errorFeedbacks) throw errorFeedbacks
      if (errorFaq) throw errorFaq
      if (errorShopNews) throw errorShopNews
    } finally {
      setIsloading(false)
      setTimeout(() => drawConstellation())
    }
  }

  useEffect(() => {
    getLanding()
  }, [])

  const scrollToContactInfo = () => {
    contactFormRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Container
      maxWidth={false}
      classes={{ root: classes.container }}
      disableGutters
    >
      {isCommonLoading ? (
        <CircularProgress size={sizeLoader} />
      ) : (
        <Container
          maxWidth={false}
          classes={{ root: classes.wrapper }}
          disableGutters
        >
          <Header handleOnCall={scrollToContactInfo} />
          <Grid
            direction="column"
            classes={{ root: classes.content }}
            container
          >
            <Heading />
            <ShortInfoPoints />
            <StoreCards />
            <CompanyInfo />
            <BrandsRunningLine />
            <CustomerEarningsCard />
            <Feedback />
            <News />
            <Faq />
            <ContactForm ref={contactFormRef} />
            <Footer />
          </Grid>
        </Container>
      )}
    </Container>
  )
}

export default memo(Landing)
