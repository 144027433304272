import React, { useEffect, useState } from 'react'

import { useIMask } from 'react-imask'

import { TextField } from '@mui/material'

import { MaskedInputProps } from './types'

const MaskedInput = ({
  mask,
  onChangeValue,
  onChangeUnmaskedValue,
  ...props
}: MaskedInputProps) => {
  const [opts] = useState({ mask })
  const { ref, value, unmaskedValue } = useIMask(opts)

  useEffect(() => {
    if (onChangeValue) onChangeValue(value)
  }, [value])

  useEffect(() => {
    if (onChangeUnmaskedValue) onChangeUnmaskedValue(unmaskedValue)
  }, [unmaskedValue])
  return <TextField {...props} variant="outlined" inputRef={ref} />
}

export default MaskedInput
