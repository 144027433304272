const COLORS = {
  backgroundColorPrimary: '#070B21',
  backgroundColorSecondary: '#12152A',
  backgroundColorThird: '#232539',
  accentColorGreen: '#49F470',
  accentColorGreenHover: '#8AFCA4',
  accentColorRed: '#FF7272',
  textColorPrimary: '#FFFFFF',
  textColorSecondary: '#DBDDEA',
  textColorThird: '#7B7F96',
  errorColor: '#F53E6A',
  borderColorThirdHalf: 'rgba(123,127,150,0.5)',
}

export default COLORS
