import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { SERVER_API, ENDPOINTS } from 'constants/Endpoints'

export const exampleApi = createApi({
  reducerPath: 'exampleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_API,
  }),
  endpoints: (builder) => ({
    createExample: builder.mutation<void, void>({
      query: () => ENDPOINTS.example,
    }),
  }),
})

export const { useCreateExampleMutation } = exampleApi
