import React, { memo, useEffect } from 'react'

import { useLocation } from 'react-router-dom'

import { Container, Grid, Typography } from '@mui/material'

import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg'
import { RootState } from 'store'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { setLayout } from 'store/reducers/landing'
import { useGetLandingMutation } from 'store/services/landingApi'
import { LandingLayout } from 'store/services/landingApi/types'
import { BaseCommonResponse } from 'store/services/types'

import useStyles from './styles'

type StateType = {
  state: {
    storeProgress?: boolean
  }
}

const NotFound: React.FC = () => {
  const classes = useStyles()
  // TODO: this code is temporary while store is progress
  const location = useLocation() as StateType
  const { state: stateLocation } = location
  const dispatch = useAppDispatch()
  const [getLandingInfo] = useGetLandingMutation()

  const telegramLink = useAppSelector(
    (state: RootState) =>
      state.landing.layout?.contact?.telegramBtn?.link ?? '',
  )

  const getLanding = async () => {
    const { data: dataLayout }: BaseCommonResponse<LandingLayout> =
      await getLandingInfo()
    if (dataLayout) dispatch(setLayout(dataLayout))
  }

  useEffect(() => {
    if (stateLocation && !telegramLink) {
      getLanding()
    }
  }, [stateLocation])

  // END TODO

  return (
    <Container
      maxWidth={false}
      classes={{
        root: classes.container,
      }}
      disableGutters
    >
      <Grid direction="column" alignItems="center" container>
        <Typography variant="h1" textAlign="center">
          404
        </Typography>
        {/* TODO: this block is temporary while store is progress */}
        {stateLocation?.storeProgress && (
          <>
            <Typography variant="body1" textAlign="center" mb={2}>
              Работа над сайтом еще ведется
            </Typography>
            <Typography variant="body1" textAlign="center" mb={2}>
              Переходи в telegram канал
            </Typography>
            <a
              href={telegramLink}
              target="_blank"
              rel="noreferrer"
              className={classes.telegramLink}
            >
              <TelegramIcon />
            </a>
          </>
        )}
        {/* END TODO */}
      </Grid>
    </Container>
  )
}

export default memo(NotFound)
