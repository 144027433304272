import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(13),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(7),
    },
  },
  arrow: {
    position: 'absolute',
    outline: 0,
    transition: 'all .5s',
    border: 0,
    background: 'transparent',
    cursor: 'pointer',
    top: 0,
  },
  arrowRight: {
    right: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      right: 0,
    },
  },
  arrowLeft: {
    right: theme.spacing(15),
    [theme.breakpoints.down('md')]: {
      right: theme.spacing(5),
    },
  },
}))

export default useStyles
