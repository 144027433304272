import { ReactComponent as BtcCoin } from 'assets/icons/btcCoin.svg'
import { ReactComponent as EthCoin } from 'assets/icons/ethCoin.svg'

export const EXCHANGE_RATES = [
  {
    label: 'btc',
    logo: BtcCoin,
    name: 'bitcoin',
  },
  {
    label: 'eth',
    logo: EthCoin,
    name: 'ethereum',
  },
]
