import { ReactComponent as AirplaneTakeoff } from 'assets/icons/airplaneTakeoff.svg'
import { ReactComponent as ChatCircleDots } from 'assets/icons/chatCircleDots.svg'
import { ReactComponent as House } from 'assets/icons/house.svg'
import { ReactComponent as ShieldCheck } from 'assets/icons/shieldCheck.svg'

export const POINTS = [
  {
    name: 'freeConsultation',
    logo: ChatCircleDots,
    label: 'Бесплатная консультация',
  },
  {
    name: 'delivery',
    logo: AirplaneTakeoff,
    label: 'Доставка в любую точку России',
  },
  {
    name: 'guarantee',
    logo: ShieldCheck,
    label: 'Гарантия на все оборудование',
  },
  {
    name: 'wholesaler',
    logo: House,
    label: 'Оптовые цены. \nРаботаем с заводами',
  },
]
