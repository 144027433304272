import React, { memo } from 'react'

import { Link } from 'react-router-dom'

import { Grid } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

import Logo from 'assets/images/logo/logoFull.png'
import useIsBreakpoint from 'hooks/useIsBreakpoint'
import usePadding from 'hooks/usePadding'
import { RootState } from 'store'
import { useAppSelector } from 'store/hooks'

import CustomButton from '../Button'
import ExchangeRates from '../ExchangeRates'
import CommunicationBtns from './CommunicationBtns'
import { LOGO_SIZE } from './contants'
import useStyles from './styles'
import { HeaderProps } from './types'

const Header: React.FC<HeaderProps> = ({
  handleOnCall = () => null,
  hideCall,
}) => {
  const classes = useStyles()
  const matches = useMediaQuery('(min-width:1250px)')
  const padding = usePadding()
  const { isMobileTablet, isMobile, isTablet, isLaptop } = useIsBreakpoint()

  const telegramLink = useAppSelector(
    (state: RootState) =>
      state.landing.layout?.contact?.telegramBtn?.link ?? '',
  )
  const rates = useAppSelector((state: RootState) => state.landing.rates)

  return (
    <Grid
      classes={{ root: classes.container }}
      justifyContent="center"
      container
    >
      <Grid
        classes={{ root: classes.content }}
        alignItems="center"
        justifyContent="space-between"
        pl={isMobileTablet ? 0 : padding}
        pr={isMobileTablet ? 0 : padding}
        direction={isMobileTablet ? 'column' : 'row'}
        container
      >
        <Grid
          classes={{ root: classes.leftContainer }}
          {...(isMobileTablet && {
            justifyContent: 'space-between',
            alignItems: 'center',
            container: true,
            pl: padding,
            pr: padding,
          })}
        >
          <Grid>
            <Link to="/">
              <img
                src={Logo}
                alt="Mining Guru"
                style={{
                  ...LOGO_SIZE.laptop,
                  ...(isMobile && LOGO_SIZE.mobile),
                  ...(isTablet && LOGO_SIZE.tablet),
                }}
              />
            </Link>
          </Grid>
          {!matches && !isLaptop && (
            <CommunicationBtns
              handleOnCall={handleOnCall}
              hideCall={hideCall}
            />
          )}
        </Grid>
        <Grid
          classes={{ root: classes.rightContainer }}
          justifyContent="flex-end"
          pl={isMobileTablet ? padding : 0}
          pr={isMobileTablet ? padding : 0}
          container
        >
          <ExchangeRates rates={rates} />
          {matches && (
            <Grid classes={{ root: classes.btnGroup }}>
              {!hideCall && (
                <CustomButton
                  label="Обратный звонок"
                  classesRoot={classes.btnCallBack}
                  onClick={handleOnCall}
                />
              )}
              <a href={telegramLink} target="_blank" rel="noreferrer">
                <CustomButton
                  label="Telegram"
                  classesRoot={classes.btnTelegram}
                  color="secondary"
                />
              </a>
            </Grid>
          )}
        </Grid>
        {!matches && isLaptop && (
          <CommunicationBtns handleOnCall={handleOnCall} hideCall={hideCall} />
        )}
      </Grid>
    </Grid>
  )
}

export default memo(Header)
