import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    top: -72,
    left: 0,
  },
}))

export default useStyles
