import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

import colors from 'config/theme/colors'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: colors.backgroundColorSecondary,
    borderRadius: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
    },
  },
  form: {
    '&.MuiGrid-root': {
      borderBottom: `1px solid ${colors.backgroundColorThird}`,
      width: '100%',
      margin: 0,

      '& .MuiGrid-item:first-child': {
        paddingLeft: 0,
      },
    },
  },
  email: {
    '&.MuiTypography-root': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontFamily: '"Montserrat Medium",sans-serif',
    },
  },
  btnRequest: {
    width: 230,
    height: 56,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  socialNetworks: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  socialIcon: {
    '&:not(:first-child)': {
      marginLeft: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
      },
    },
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  },
  formInput: {
    width: '100%',
  },
  phoneEmail: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}))

export default useStyles
