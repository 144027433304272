import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'

import ExampleReducer from './reducers/example'
import LandingReducer from './reducers/landing'
import NewsReducer from './reducers/news'
import { exampleApi } from './services/exampleApi'
import { feedbacksApi } from './services/feedbacksApi'
import { landingApi } from './services/landingApi'
import { newsApi } from './services/newsApi'
import { newsShopApi } from './services/newsShopApi'
import { requestsApi } from './services/requestsApi'

export const store = configureStore({
  reducer: {
    example: ExampleReducer,
    landing: LandingReducer,
    news: NewsReducer,
    [exampleApi.reducerPath]: exampleApi.reducer,
    [landingApi.reducerPath]: landingApi.reducer,
    [requestsApi.reducerPath]: requestsApi.reducer,
    [newsApi.reducerPath]: newsApi.reducer,
    [feedbacksApi.reducerPath]: feedbacksApi.reducer,
    [newsShopApi.reducerPath]: newsShopApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      landingApi.middleware,
      requestsApi.middleware,
      newsApi.middleware,
      feedbacksApi.middleware,
      newsShopApi.middleware,
    ),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
