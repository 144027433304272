import React, { memo } from 'react'

import moment from 'moment'

import { Grid, Typography } from '@mui/material'

import { ReactComponent as LogoTitle } from 'assets/images/logo/logoTitle.svg'
import useIsBreakpoint from 'hooks/useIsBreakpoint'
import usePadding from 'hooks/usePadding'
import { RootState } from 'store'
import { useAppSelector } from 'store/hooks'

import useStyles from './styles'

const CURRENT_YEAR = moment().get('year')

const Footer: React.FC = () => {
  const classes = useStyles()
  const padding = usePadding()
  const { isMobile } = useIsBreakpoint()

  const privacyPolicyLink = useAppSelector(
    (state: RootState) => state.landing.layout?.privacyPolicy?.link ?? '',
  )

  return (
    <Grid
      p={padding}
      pb={7}
      classes={{ root: classes.wrapper }}
      direction="column"
      container
      {...(isMobile && { pt: 4 })}
    >
      <Grid container>
        <LogoTitle height="auto" />
      </Grid>
      <Grid
        alignItems="center"
        justifyContent="space-between"
        classes={{ root: classes.container }}
        container
      >
        <Typography variant="body2" classes={{ root: classes.text }}>
          {`©${CURRENT_YEAR}. Ation. Все права защищены`}
        </Typography>
        <a href={privacyPolicyLink} target="_blank" rel="noreferrer">
          <Typography variant="body2" classes={{ root: classes.text }}>
            Политика конфиденциальности
          </Typography>
        </a>
      </Grid>
    </Grid>
  )
}

export default memo(Footer)
