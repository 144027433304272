import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    top: `-${theme.spacing(9)}`,
    left: 0,

    [theme.breakpoints.down('md')]: {
      top: theme.spacing(2),
    },
  },
}))

export default useStyles
