import React, { memo } from 'react'

import { Grid } from '@mui/material'

import useIsBreakpoint from 'hooks/useIsBreakpoint'
import usePadding from 'hooks/usePadding'

import ShortInfoPoint from '../ShortInfoPoint'
import { POINTS } from './constants'
import useStyles from './styles'

const ShortInfoPoints: React.FC = () => {
  const classes = useStyles()
  const padding = usePadding()
  const { isMobileTablet } = useIsBreakpoint()

  return (
    <Grid
      alignItems={isMobileTablet ? 'flex-start' : 'center'}
      justifyContent="space-between"
      classes={{ root: classes.container }}
      pl={padding}
      pr={padding}
      mb={isMobileTablet ? 21 : 18}
      container
    >
      {POINTS.map((point) => (
        <ShortInfoPoint
          logo={point.logo}
          label={point.label}
          key={point.name}
        />
      ))}
    </Grid>
  )
}

export default memo(ShortInfoPoints)
