import React, { memo } from 'react'

import { Grid, Typography } from '@mui/material'

import useIsBreakpoint from 'hooks/useIsBreakpoint'
import usePadding from 'hooks/usePadding'

import Carousel from '../Carousel'
import useStyles from './styles'
import { CarouselContentProps } from './types'

const CarouselContent: React.FC<CarouselContentProps> = ({
  children,
  title,
  marginBottom,
}) => {
  const padding = usePadding()
  const classes = useStyles()
  const { isMobileTablet } = useIsBreakpoint()

  return (
    <Grid
      pl={padding}
      pr={isMobileTablet ? 2 : 3}
      mb={marginBottom}
      direction="column"
      classes={{ root: classes.container }}
      container
    >
      <Typography variant="h2" classes={{ root: classes.title }}>
        {title}
      </Typography>
      <Carousel>{children}</Carousel>
    </Grid>
  )
}

export default memo(CarouselContent)
