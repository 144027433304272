import { ReactComponent as Facebook } from 'assets/icons/facebook.svg'
import { ReactComponent as Instagram } from 'assets/icons/instagram.svg'
import { ReactComponent as Telegram } from 'assets/icons/telegram.svg'
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg'
import { ReactComponent as Vk } from 'assets/icons/vk.svg'
import { ReactComponent as Whatsapp } from 'assets/icons/whatsapp.svg'
import { ReactComponent as Youtube } from 'assets/icons/youtube.svg'

import { SocialNetworkIcons, RequestMessagesType } from './types'

export const SOCIAL_NETWORK_ICONS: SocialNetworkIcons = {
  telegram: Telegram,
  whatsapp: Whatsapp,
  facebook: Facebook,
  instagram: Instagram,
  twitter: Twitter,
  youtube: Youtube,
  vk: Vk,
}

export const REQUEST_MESSAGES: RequestMessagesType = {
  success: 'Ваша заявка принята',
  error: 'Не удалось отправить, попробуйте снова',
  info: '',
  warning: '',
}
