import { AlertColor } from '@mui/material'

type RequestMessagesType = {
  [key: string]: {
    [keys in AlertColor]: string
  }
}

const REQUEST_MESSAGES: RequestMessagesType = {
  newsLoading: {
    success: '',
    error: 'Не удалось загрузить новость, попробуйте снова',
    warning: '',
    info: '',
  },
}

export default REQUEST_MESSAGES
