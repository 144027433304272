import React, { memo } from 'react'

import { Grid, Typography } from '@mui/material'

import useIsBreakpoint from 'hooks/useIsBreakpoint'

import CustomAvatar from '../Avatar'
import CarouselCard from '../CarouselCard'
import useStyles from './styles'
import { FeedbackCardProps } from './types'

const FeedbackCard: React.FC<FeedbackCardProps> = ({ user, feedback }) => {
  const classes = useStyles()
  const { isMobileTablet } = useIsBreakpoint()

  return (
    <CarouselCard>
      <Grid
        direction={isMobileTablet ? 'row' : 'column'}
        classes={{ root: classes.user }}
        container
      >
        <CustomAvatar user={user} />
        <Typography
          mt={isMobileTablet ? 0 : 3}
          ml={isMobileTablet ? 2 : 0}
          variant="h4"
          classes={{ root: classes.userName }}
        >
          {`${user.name} ${user.surname}`}
        </Typography>
      </Grid>
      <Typography
        variant="body2"
        mt={3}
        classes={{ root: classes.feedback }}
        {...(!isMobileTablet && { color: 'secondary' })}
      >
        {feedback}
      </Typography>
    </CarouselCard>
  )
}

export default memo(FeedbackCard)
