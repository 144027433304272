import React from 'react'

import { Route, RouteProps } from 'react-router-dom'

const mapRoutes = (routes: RouteProps[]): React.ReactNode =>
  routes.map((route: RouteProps) => (
    <Route key={route.path} path={route.path} element={route.element} />
  ))

export default mapRoutes
