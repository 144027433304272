import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  brand: {
    '&.MuiGrid-root': {
      width: 217,
      height: 152,

      [theme.breakpoints.down('md')]: {
        width: 178,
        height: 125,
      },
    },
  },
  logo: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '70%',
    },
  },
}))

export default useStyles
