import React, { memo } from 'react'

import useIsBreakpoint from 'hooks/useIsBreakpoint'
import { RootState } from 'store'
import { useAppSelector } from 'store/hooks'

import CarouselContent from '../CarouselContent'
import NewsCard from '../NewsCard'

const News: React.FC = () => {
  const { isMobileTablet } = useIsBreakpoint()

  const news = useAppSelector((state: RootState) => state.landing.news)

  if (!news.length) return null

  return (
    <CarouselContent title="Новости" marginBottom={isMobileTablet ? 10 : 20}>
      {news.map((newsItem) => (
        <NewsCard key={newsItem.id} news={newsItem} />
      ))}
    </CarouselContent>
  )
}

export default memo(News)
