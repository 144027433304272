import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

import colors from 'config/theme/colors'

const useStyles = makeStyles((theme: Theme) => ({
  faq: {
    borderBottom: `1px solid ${colors.textColorThird}`,
  },
  accordion: {
    '&.MuiAccordion-root': {
      backgroundColor: colors.backgroundColorPrimary,
      minHeight: 98,
      boxShadow: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      '&:before': {
        height: 0,
        opacity: 0,
      },
      borderBottom: `1px solid ${colors.textColorThird}`,
    },
  },
  expanded: {
    '&.MuiAccordion-root': {
      minHeight: 98,
    },
  },
  details: {
    '&.MuiAccordionDetails-root': {
      padding: `0 ${theme.spacing(10)} ${theme.spacing(4)} 0`,
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },
  },
  summary: {
    '&.MuiAccordionSummary-root': {
      padding: 0,
      minHeight: 98,
      justifyContent: 'space-between',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'none',
      width: 60,
      height: 60,
      justifyContent: 'center',
      alignItems: 'center',
      '&.Mui-expanded': {
        transform: 'rotate(45deg)',
        backgroundColor: colors.backgroundColorThird,
        borderRadius: '50%',
      },
      [theme.breakpoints.down('md')]: {
        width: 48,
        height: 48,
      },
    },
  },
  summaryContent: {
    maxWidth: 'calc(100% - 60px)',
  },
  title: {
    '&.MuiTypography-root': {
      [theme.breakpoints.down('md')]: {
        fontWeight: 600,
        fontFamily: 'Montserrat SemiBold',
      },
    },
  },
}))

export default useStyles
