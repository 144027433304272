import React, { memo } from 'react'

import { Grid } from '@mui/material'

import useIsBreakpoint from 'hooks/useIsBreakpoint'

import useStyles from './styles'
import { CarouselCardProps } from './types'

const CarouselCard: React.FC<CarouselCardProps> = ({ children, padding }) => {
  const classes = useStyles()
  const { isMobileTablet } = useIsBreakpoint()

  const paddingCard = isMobileTablet ? 3 : 5

  return (
    <Grid
      classes={{ root: classes.container }}
      direction="column"
      p={padding ?? paddingCard}
      container
    >
      {children}
    </Grid>
  )
}

export default memo(CarouselCard)
