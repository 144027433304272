import React from 'react'

import Landing from 'pages/Landing'
import News from 'pages/News'
import NotFound from 'pages/NotFound'

import { ROUTES } from './constants'

const ROUTES_LIST = [
  {
    path: ROUTES.landing,
    element: <Landing />,
  },
  {
    path: ROUTES.news,
    element: <News />,
  },
  {
    path: ROUTES.notFound,
    element: <NotFound />,
  },
]

export default ROUTES_LIST
