import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

import colors from 'config/theme/colors'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: 'flex',
    maxWidth: 640,
    width: '49%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },
    },
  },
  container: {
    backgroundColor: colors.backgroundColorThird,
    borderRadius: theme.spacing(6),
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      borderRadius: theme.spacing(3),
    },
  },
  ellipse: {
    '&.MuiGrid-root': {
      width: 330,
      [theme.breakpoints.down('md')]: {
        width: 208,
        height: 208,
        right: 0,
        bottom: 0,
      },
    },
    height: 330,
    borderRadius: '50%',
    position: 'absolute',
    right: -74,
    bottom: -74,
    background: colors.accentColorGreen,
    opacity: 0.6,
    filter: 'blur(375.597px)',
  },
  redirectBtn: {
    '&.MuiGrid-root': {
      width: 72,
      [theme.breakpoints.down('md')]: {
        width: 56,
        height: 56,
      },
    },
    height: 72,
    background: colors.accentColorGreen,
  },
}))

export default useStyles
