import React, { memo } from 'react'

import { Grid, Typography } from '@mui/material'

import useIsBreakpoint from 'hooks/useIsBreakpoint'

import useStyles from './styles'
import { ShortInfoPointProps } from './types'

const ICON_SIZE_MOBILE = 56

const ShortInfoPoint: React.FC<ShortInfoPointProps> = ({
  logo: Logo,
  label,
}) => {
  const classes = useStyles()
  const { isMobileTablet, isLaptop } = useIsBreakpoint()

  return (
    <Grid direction="column" classes={{ root: classes.container }} container>
      <Logo
        {...(isMobileTablet && {
          width: ICON_SIZE_MOBILE,
          height: ICON_SIZE_MOBILE,
        })}
      />
      <Typography
        variant="body1"
        classes={{ root: classes.label }}
        color="secondary"
        mt={isMobileTablet || isLaptop ? 0 : 3}
        ml={isMobileTablet || isLaptop ? 3 : 0}
      >
        {label}
      </Typography>
    </Grid>
  )
}

export default memo(ShortInfoPoint)
