import React, { memo } from 'react'

import { Grid, Typography, Link } from '@mui/material'

import { ReactComponent as ArrowNext } from 'assets/icons/arrowNext.svg'
import useIsBreakpoint from 'hooks/useIsBreakpoint'

import { CARD_ICON_SIZE } from './constants'
import useStyles from './styles'
import { StoreCardProps } from './types'

const StoreCard: React.FC<StoreCardProps> = ({
  logo: Logo,
  title,
  description,
  link,
}) => {
  const classes = useStyles()
  const { isMobileTablet, isLaptop } = useIsBreakpoint()

  const marginBottomTextCard = isLaptop ? 5 : 11
  const paddingCard = isLaptop ? 5 : 7

  return (
    <Link
      component="a"
      href={link}
      target="_blank"
      rel="noreferrer"
      className={classes.link}
    >
      <Grid
        classes={{ root: classes.container }}
        p={isMobileTablet ? 3 : paddingCard}
        container
      >
        <Grid
          direction="column"
          mb={isMobileTablet ? 4 : marginBottomTextCard}
          container
        >
          <Typography variant="h3" mb={3}>
            {title}
          </Typography>
          {description.map(({ text, key }) => (
            <Typography key={key} variant="body1" mb={4}>
              {text}
            </Typography>
          ))}
        </Grid>
        <Grid alignItems="flex-end" justifyContent="space-between" container>
          <Logo
            {...{
              ...(isMobileTablet && {
                width: CARD_ICON_SIZE.mobileTablet.width,
                height: CARD_ICON_SIZE.mobileTablet.height,
              }),
              ...(isLaptop && {
                width: CARD_ICON_SIZE.laptop.width,
                height: CARD_ICON_SIZE.laptop.height,
              }),
            }}
          />
          <Grid
            alignItems="center"
            justifyContent="center"
            classes={{ root: classes.ellipse }}
            container
          />
          <Grid
            alignItems="center"
            justifyContent="center"
            classes={{ root: classes.redirectBtn }}
            borderRadius="50%"
            container
          >
            <ArrowNext />
          </Grid>
        </Grid>
      </Grid>
    </Link>
  )
}

export default memo(StoreCard)
