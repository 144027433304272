import React, { memo } from 'react'

import { Alert, AlertProps, Snackbar } from '@mui/material'

import { AlertMessageProps } from './types'

const AlertCustom = React.forwardRef<HTMLDivElement, AlertProps>(
  (props, ref) => <Alert elevation={6} ref={ref} variant="filled" {...props} />,
)

const AlertMessage: React.FC<AlertMessageProps> = ({
  open,
  handleClose,
  message,
  severity = 'success',
}) => (
  <Snackbar
    open={open}
    autoHideDuration={5000}
    onClose={handleClose}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
  >
    <AlertCustom
      onClose={handleClose}
      severity={severity}
      sx={{ width: '100%' }}
    >
      {message}
    </AlertCustom>
  </Snackbar>
)

export default memo(AlertMessage)
