import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ExampleState {
  isLoading: boolean
}

const initialState: ExampleState = {
  isLoading: false,
}

const exampleSlice = createSlice({
  name: 'example',
  initialState,
  reducers: {
    app: () => initialState,
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
  },
})

export const { app, setLoading } = exampleSlice.actions

export default exampleSlice.reducer
