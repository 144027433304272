import React, { memo } from 'react'

import { Grid } from '@mui/material'

import useIsBreakpoint from 'hooks/useIsBreakpoint'
import usePadding from 'hooks/usePadding'
import { RootState } from 'store'
import { useAppSelector } from 'store/hooks'

import StoreCard from '../StoreCard'
import { CARDS } from './constants'
import useStyles from './styles'
import { CustomStoreCardProps } from './types'

const StoreCards: React.FC = () => {
  const classes = useStyles()
  const padding = usePadding()
  const { isMobileTablet } = useIsBreakpoint()

  const cards = useAppSelector(
    (state: RootState) => state.landing.layout?.storeBtns ?? {},
  )

  return (
    <Grid
      justifyContent="space-between"
      classes={{ root: classes.container }}
      pl={padding}
      pr={padding}
      mb={isMobileTablet ? 2 : 12}
      container
    >
      {CARDS.map((card: CustomStoreCardProps) => {
        const currentCard = cards[card.name]

        if (!currentCard) return null

        return (
          <StoreCard
            logo={card.logo}
            title={currentCard.title}
            description={currentCard.description}
            link={currentCard.link}
            key={card.name}
          />
        )
      })}
    </Grid>
  )
}

export default memo(StoreCards)
