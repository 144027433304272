import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

import colors from 'config/theme/colors'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: colors.backgroundColorSecondary,
    borderRadius: theme.spacing(6),
    maxWidth: '92%',
    height: '100%',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      borderRadius: theme.spacing(3),
      maxWidth: '98%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}))

export default useStyles
