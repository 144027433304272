import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { SERVER_API, ENDPOINTS } from 'constants/Endpoints'

import { BaseResponse } from '../types'
import { Request, RequestResponse } from './types'

export const requestsApi = createApi({
  reducerPath: 'requestsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_API,
  }),
  endpoints: (builder) => ({
    createRequest: builder.mutation<RequestResponse, Request>({
      query: (body) => ({
        body,
        url: ENDPOINTS.requests,
        method: 'POST',
      }),
      transformResponse: ({ data }: BaseResponse<RequestResponse>) => data,
    }),
  }),
})

export const { useCreateRequestMutation } = requestsApi
