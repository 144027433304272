import React, { memo } from 'react'

import { Grid, Typography } from '@mui/material'

import useIsBreakpoint from 'hooks/useIsBreakpoint'
import usePadding from 'hooks/usePadding'
import { RootState } from 'store'
import { useAppSelector } from 'store/hooks'
import { DescriptionItem } from 'store/services/landingApi/types'

import ReadMore from '../ReadMore'
import { MAX_HEIGHT_COMPANY_TEXT } from './constants'
import useStyles from './styles'

const mapParagraphs = (
  paragraphs: DescriptionItem[],
  spacingText: number,
): React.ReactNode => (
  <>
    {paragraphs.map(({ key, text }) => (
      <Typography key={key} variant="body1" mb={spacingText}>
        {text}
      </Typography>
    ))}
  </>
)

const CompanyInfo: React.FC = () => {
  const classes = useStyles()

  const padding = usePadding()
  const { isMobileTablet, isMobile, isLaptop } = useIsBreakpoint()
  const spacingText = isMobileTablet ? 3 : 4
  const maxHeightTextBeforeLaptop = isMobile
    ? MAX_HEIGHT_COMPANY_TEXT.mobile
    : MAX_HEIGHT_COMPANY_TEXT.tablet
  const maxHeightText = isLaptop
    ? MAX_HEIGHT_COMPANY_TEXT.laptop
    : maxHeightTextBeforeLaptop

  const withReadMore = isLaptop || isMobileTablet

  const title = useAppSelector(
    (state: RootState) => state.landing.layout?.companyInfo?.title ?? '',
  )

  const description = useAppSelector(
    (state: RootState) => state.landing.layout?.companyInfo?.description ?? [],
  )

  const subDescription = useAppSelector(
    (state: RootState) =>
      state.landing.layout?.companyInfo?.subDescription ?? [],
  )

  return (
    <Grid
      pl={padding}
      pr={padding}
      mb={isMobileTablet ? 3 : 6}
      justifyContent="space-between"
      container
    >
      <Grid
        direction="column"
        classes={{ root: classes.description }}
        container
      >
        <Typography mb={spacingText} variant="h2">
          {title}
        </Typography>
        {!withReadMore && mapParagraphs(description, spacingText)}
      </Grid>
      <Grid
        direction="column"
        classes={{ root: classes.subDescription }}
        container
      >
        {!withReadMore && mapParagraphs(subDescription, spacingText)}

        {withReadMore && (
          <ReadMore maxHeight={maxHeightText}>
            {mapParagraphs([...description, ...subDescription], spacingText)}
          </ReadMore>
        )}
      </Grid>
    </Grid>
  )
}

export default memo(CompanyInfo)
