import { makeStyles } from '@mui/styles'

import colors from 'config/theme/colors'

const useStyles = makeStyles(() => ({
  container: {
    '&.MuiContainer-root': {
      backgroundColor: colors.backgroundColorPrimary,
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  wrapper: {
    '&.MuiContainer-root': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  content: {
    '&.MuiGrid-root': {
      maxWidth: 1440,
    },
  },
}))

export default useStyles
