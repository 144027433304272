import React, { memo, useState } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material'

import { ReactComponent as AccordionIcon } from 'assets/icons/accordionIcon.svg'
import useIsBreakpoint from 'hooks/useIsBreakpoint'
import usePadding from 'hooks/usePadding'
import { RootState } from 'store'
import { useAppSelector } from 'store/hooks'

import useStyles from './styles'

const SIZE_ICON = 32
const SIZE_ICON_MOBILE = 24

const Faq: React.FC = () => {
  const classes = useStyles()
  const padding = usePadding()
  const { isMobileTablet } = useIsBreakpoint()

  const sizeIcon = isMobileTablet ? SIZE_ICON_MOBILE : SIZE_ICON

  const [expanded, setExpanded] = useState<string | false>(false)

  const faq = useAppSelector((state: RootState) => state.landing.faq)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  if (!faq.length) return null

  return (
    <Grid pl={padding} pr={padding} mb={isMobileTablet ? 10 : 20} container>
      <Grid classes={{ root: classes.faq }} pb={5} container>
        <Typography variant="h2" textTransform="uppercase">
          Faq
        </Typography>
      </Grid>
      {faq.map((faqItem) => (
        <Accordion
          key={faqItem.id}
          expanded={expanded === faqItem.id}
          onChange={handleChange(faqItem.id)}
          classes={{ root: classes.accordion, expanded: classes.expanded }}
          disableGutters
          square
        >
          <AccordionSummary
            classes={{ root: classes.summary, content: classes.summaryContent }}
            aria-controls={`${faqItem.id}-content`}
            id={`${faqItem.id}-header`}
            expandIcon={<AccordionIcon width={sizeIcon} height={sizeIcon} />}
          >
            <Typography
              variant="body1"
              classes={{ root: classes.title }}
              color="secondary"
            >
              {faqItem.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.details }}>
            {faqItem.description.map(({ key, text }) => (
              <Typography
                key={key}
                variant="body1"
                mb={4}
                {...(!isMobileTablet && { color: 'secondary' })}
              >
                {text}
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  )
}

export default memo(Faq)
