import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { News } from 'store/services/landingApi/types'

import { NewsState } from './types'

const initialState: NewsState = {
  currentNews: null,
}

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    app: () => initialState,
    setNews(state, action: PayloadAction<News>) {
      state.currentNews = action.payload
    },
  },
})

export const { app, setNews } = newsSlice.actions

export default newsSlice.reducer
