import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

import colors from 'config/theme/colors'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '&.MuiButtonBase-root': {
      minHeight: 48,
      minWidth: 117,
      borderRadius: theme.spacing(10),
      textTransform: 'unset',
      color: colors.backgroundColorPrimary,

      '&.MuiButton-textPrimary': {
        color: colors.accentColorGreen,
        fontSize: 17,
        lineHeight: '24px',
        fontFamily: 'Montserrat Medium',
        fontWeight: 500,
        padding: 0,
        minWidth: 'unset',
      },
    },
    '&.MuiButton-containedPrimary:hover': {
      backgroundColor: colors.accentColorGreenHover,
    },
    '&.MuiButton-containedSecondary:hover': {
      backgroundColor: colors.accentColorGreen,
    },
    '&.Mui-disabled': {
      opacity: 0.5,
    },
    '&.MuiButton-containedPrimary.Mui-disabled': {
      backgroundColor: colors.accentColorGreen,
      color: colors.backgroundColorPrimary,
    },
    '&.MuiButton-containedSecondary.Mui-disabled': {
      backgroundColor: colors.textColorPrimary,
      color: colors.backgroundColorPrimary,
    },
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
  },
}))

export default useStyles
