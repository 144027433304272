import React, {
  memo,
  useState,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react'

import validator from 'validator'

import { AlertColor, Grid, TextField, Typography } from '@mui/material'

import { PADDING, PADDING_MOBILE, PADDING_TABLET } from 'constants/Layout'
import { PHONE_MASK, PHONE_PLACEHOLDER } from 'constants/Masks'
import { VALIDATOR_LOCALE_RU } from 'constants/Validator'
import useIsBreakpoint from 'hooks/useIsBreakpoint'
import useTimerSeconds from 'hooks/useTimerSeconds'
import { RootState } from 'store'
import { useAppSelector } from 'store/hooks'
import { useCreateRequestMutation } from 'store/services/requestsApi'
import { Request } from 'store/services/requestsApi/types'
import { BaseCommonResponse } from 'store/services/types'

import AleftMessage from '../AlertMessage'
import CustomButton from '../Button'
import MaskedInput from '../MaskedInput'
import { SOCIAL_NETWORK_ICONS, REQUEST_MESSAGES } from './constants'
import useStyles from './styles'
import { ContactFormProps } from './types'

const ContactForm: ForwardRefRenderFunction<
  HTMLDivElement | null,
  ContactFormProps
> = (props, ref) => {
  const classes = useStyles()
  const { isMobileTablet, isTablet } = useIsBreakpoint()
  const { seconds, setSeconds } = useTimerSeconds()
  const padding = isMobileTablet ? 0 : PADDING
  const horizontalPaddingInnerContainer = isTablet
    ? PADDING_TABLET
    : PADDING_MOBILE

  const [createRequest, { isLoading: isCreatingRequest }] =
    useCreateRequestMutation()

  const [name, setName] = useState<string>('')
  const [nameError, setNameError] = useState<boolean>(false)
  const [phone, setPhone] = useState<string>('')
  const [phoneError, setPhoneError] = useState<boolean>(false)
  const [unmaskedPhone, setUnmaskedPhone] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const [openAleft, setOpenAlert] = useState<boolean>(false)
  const [severityAleft, setSeverityAlert] = useState<AlertColor>('success')

  const title = useAppSelector(
    (state: RootState) => state.landing?.layout?.requestBox?.title ?? '',
  )

  const description = useAppSelector(
    (state: RootState) => state.landing?.layout?.requestBox?.description ?? [],
  )

  const contactInfo = useAppSelector(
    (state: RootState) => state.landing?.layout?.contact ?? null,
  )

  const phoneContact = useAppSelector(
    (state: RootState) => state.landing?.layout?.contact?.phone?.title ?? '',
  )

  const emailContact = useAppSelector(
    (state: RootState) => state.landing?.layout?.contact?.email?.title ?? '',
  )

  const clearForm = () => {
    setName('')
    setUnmaskedPhone('')
    setEmail('')
    setMessage('')
    setPhone('')

    setNameError(false)
    setPhoneError(false)
    setEmailError(false)
  }

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
    if (nameError) setNameError(false)
  }

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)

    if (emailError) setEmailError(false)
    if (phoneError) setPhoneError(false)
  }

  const onChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value)
  }

  const onChangePhone = (newValue: string) => {
    if (newValue === phone) return
    setPhone(newValue)

    if (emailError) setEmailError(false)
    if (phoneError) setPhoneError(false)
  }

  const onChangeUnmaskedPhone = (newValue: string) => {
    setUnmaskedPhone(`+${newValue}`)
  }

  const validateForm = () => {
    const isValidName =
      !!name.trim() && validator.isAlpha(name, VALIDATOR_LOCALE_RU)
    const isEmptyPhone = !phone.trim()
    const isEmptyEmail = !email.trim()

    const isValidPhone = validator.isMobilePhone(
      unmaskedPhone,
      VALIDATOR_LOCALE_RU,
    )

    const isValidEmail = validator.isEmail(email)

    const isValidNameWithPhone = isValidName && isValidPhone
    const isValidNameWithEmail = isValidName && isValidEmail

    const nameErr = !isValidName

    const emptyPhoneEmail = isEmptyPhone && isEmptyEmail

    const emailErr = emptyPhoneEmail || (isEmptyPhone && !isValidEmail)
    const phoneErr = emptyPhoneEmail || (isEmptyEmail && !isValidPhone)

    setNameError(nameErr)
    setPhoneError(phoneErr)
    setEmailError(emailErr)

    return isValidNameWithPhone || isValidNameWithEmail
  }

  const sendRequest = async () => {
    setOpenAlert(false)
    const isValidForm = validateForm()

    if (isValidForm) {
      try {
        const bodyRequest = {
          name,
          ...(phone && { phone: unmaskedPhone }),
          ...(email && { email }),
          ...(message && { message }),
        }

        const { error }: BaseCommonResponse<Request> = await createRequest(
          bodyRequest,
        )
        if (error) throw error
        setSeverityAlert('success')
        clearForm()
      } catch (e) {
        setSeverityAlert('error')
      } finally {
        setOpenAlert(true)
        setSeconds(60)
      }
    }
  }

  const handleCloseAlert = () => setOpenAlert(false)

  return (
    <div ref={ref}>
      <Grid pl={padding} pr={padding} container>
        <Grid
          classes={{ root: classes.container }}
          pt={7}
          pl={isMobileTablet ? horizontalPaddingInnerContainer : 7}
          pr={isMobileTablet ? horizontalPaddingInnerContainer : 7}
          pb={isMobileTablet ? 5 : 7}
          direction="column"
          container
        >
          <Grid
            spacing={isMobileTablet ? 0 : 4}
            classes={{ root: classes.form }}
            direction={isMobileTablet ? 'column' : 'row'}
            pb={isMobileTablet ? 4 : 8}
            container
          >
            <Grid lg={6} item>
              <Typography variant="h2" mb={isMobileTablet ? 2 : 1}>
                {title}
              </Typography>
              {description.map(({ key, text }) => (
                <Typography key={key} variant="body1" mb={4}>
                  {text}
                </Typography>
              ))}
            </Grid>
            <Grid
              direction="column"
              classes={{ root: classes.formInput }}
              lg={6}
              display="flex"
              container
              item
            >
              <TextField
                name="name"
                value={name}
                placeholder="Имя"
                error={nameError}
                onChange={onChangeName}
              />
              <MaskedInput
                name="phone"
                value={phone}
                mask={PHONE_MASK}
                placeholder={PHONE_PLACEHOLDER}
                error={phoneError}
                onChangeUnmaskedValue={onChangeUnmaskedPhone}
                onChangeValue={onChangePhone}
              />
              <TextField
                name="email"
                value={email}
                placeholder="Email"
                error={emailError}
                onChange={onChangeEmail}
              />
              <TextField
                name="message"
                value={message}
                placeholder="Ваше сообщение"
                onChange={onChangeMessage}
                maxRows={3}
                multiline
              />
              <Grid justifyContent="flex-end" mt={2} container>
                <CustomButton
                  label={seconds ? `${seconds}` : 'Отправить заявку'}
                  classesRoot={classes.btnRequest}
                  onClick={sendRequest}
                  isLoading={isCreatingRequest}
                  disabled={isCreatingRequest || !!seconds}
                  withLoading
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            spacing={isMobileTablet ? 0 : 4}
            pt={isMobileTablet ? 4 : 6}
            container
          >
            <Grid
              lg={6}
              mb={isMobileTablet ? 4 : 0}
              classes={{ root: classes.phoneEmail }}
              item
            >
              <a href={`tel:${phoneContact}`}>
                <Typography variant="h3">{phoneContact}</Typography>
              </a>
              <a
                href={`mailto:${emailContact}`}
                target="_blank"
                rel="noreferrer"
              >
                <Typography variant="body1" classes={{ root: classes.email }}>
                  {emailContact}
                </Typography>
              </a>
            </Grid>
            <Grid lg={6} classes={{ root: classes.socialNetworks }} item>
              {Object.entries(SOCIAL_NETWORK_ICONS).map(([social, Icon]) => {
                const currentSocial = contactInfo?.[social]

                if (!currentSocial || !currentSocial?.link) return null

                return (
                  <a
                    key={currentSocial.id}
                    href={currentSocial.link}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.socialIcon}
                  >
                    <Icon />
                  </a>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AleftMessage
        open={openAleft}
        message={REQUEST_MESSAGES[severityAleft]}
        severity={severityAleft}
        handleClose={handleCloseAlert}
      />
    </div>
  )
}

export default memo(forwardRef(ContactForm))
