import { createTheme } from '@mui/material'

import colors from './colors'

const theme = createTheme()

const customTheme = createTheme(theme, {
  palette: {
    primary: {
      main: colors.accentColorGreen,
    },
    secondary: {
      main: colors.textColorPrimary,
    },
    success: {
      main: colors.accentColorGreen,
    },
    error: {
      main: colors.errorColor,
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: colors.textColorPrimary,
          fontSize: 19,
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          marginBottom: 16,
          '&.MuiOutlinedInput-root': {
            '&.MuiInputBase-multiline': {
              padding: 0,
              '& > textarea': {
                minHeight: 32,
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              },
            },
            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderBottomWidth: 1,
              },
              '&:hover': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderBottomColor: colors.accentColorGreen,
                },
              },
            },
            '&:hover': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderBottomColor: colors.textColorThird,
              },
            },
          },
          '& .MuiOutlinedInput-input': {
            padding: '12px 0',
            height: 32,
            border: 'none',
            '&::-webkit-input-placeholder': {
              opacity: 1,
              color: colors.textColorThird,
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            borderBottom: `1px solid ${colors.textColorThird}`,
            borderRadius: 0,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '&.MuiAlert-root': {
            alignItems: 'center',
          },
          '& .MuiAlert-message': {
            fontSize: 19,
            lineHeight: '32px',
            fontFamily: 'Montserrat SemiBold',
            fontWeight: 600,
            padding: 0,

            [theme.breakpoints.down('md')]: {
              fontSize: 15,
              lineHeight: '22px',
            },
          },
          '& .MuiAlert-action': {
            padding: 0,
            '& .MuiSvgIcon-root': {
              width: 30,
              height: 30,
            },
          },
          '& .MuiAlert-icon .MuiSvgIcon-root': {
            width: 30,
            height: 30,
            padding: 0,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'unset',
        },
      },
    },
  },
  typography: {
    fontFamily: 'EuropeExt Bold,sans-serif',
    h1: {
      fontFamily: '"EuropeExt Bold",sans-serif',
      fontWeight: 700,
      fontSize: 90,
      lineHeight: '96px',
      color: colors.textColorPrimary,
      letterSpacing: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: 40,
        lineHeight: '48px',
      },
    },
    h2: {
      fontFamily: '"EuropeExt Bold",sans-serif',
      fontWeight: 700,
      fontSize: 60,
      lineHeight: '64px',
      color: colors.textColorPrimary,
      letterSpacing: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: 34,
        lineHeight: '40px',
      },
    },
    h3: {
      fontFamily: '"EuropeExt Bold",sans-serif',
      fontWeight: 700,
      fontSize: 40,
      lineHeight: '48px',
      color: colors.textColorPrimary,
      letterSpacing: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: 24,
        lineHeight: '32px',
      },
    },
    h4: {
      fontFamily: '"EuropeExt Bold",sans-serif',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '32px',
      color: colors.textColorPrimary,
      letterSpacing: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
        lineHeight: '24px',
      },
    },
    h5: {
      fontFamily: '"Montserrat SemiBold",sans-serif',
      fontWeight: 600,
      fontSize: 21,
      lineHeight: '32px',
      color: colors.textColorThird,
      letterSpacing: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: 17,
        lineHeight: '24px',
      },
    },
    body1: {
      fontFamily: '"Montserrat Regular",sans-serif',
      fontWeight: 400,
      fontSize: 21,
      lineHeight: '32px',
      color: colors.textColorSecondary,
      letterSpacing: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: 17,
        lineHeight: '24px',
      },
    },
    body2: {
      fontFamily: '"Montserrat Regular",sans-serif',
      fontWeight: 400,
      fontSize: 19,
      lineHeight: '32px',
      color: colors.textColorThird,
      letterSpacing: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: 17,
        lineHeight: '24px',
      },
    },
    subtitle1: {
      fontFamily: '"Montserrat Bold",sans-serif',
      fontWeight: 700,
      fontSize: 21,
      lineHeight: '32px',
      color: colors.backgroundColorPrimary,
      letterSpacing: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
        lineHeight: '28px',
      },
    },
    button: {
      fontFamily: 'Montserrat SemiBold',
      fontWeight: 600,
      fontSize: 19,
      lineHeight: '32px',
      color: colors.backgroundColorPrimary,
      letterSpacing: 0,
    },
    caption: {
      fontFamily: 'Inter SemiBold',
      fontWeight: 600,
      fontSize: 15,
      lineHeight: '22px',
      color: colors.textColorThird,
      letterSpacing: 1.75,
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
        lineHeight: '22px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        lineHeight: '22px',
      },
    },
  },
})

export default customTheme
