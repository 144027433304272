import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const IMAGE_HEIGHT = 280
const IMAGE_HEIGHT_MOBILE = 230

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    position: 'relative',
  },
  img: {
    height: IMAGE_HEIGHT,
    objectFit: 'fill',
    width: '100%',

    [theme.breakpoints.down('md')]: {
      height: IMAGE_HEIGHT_MOBILE,
    },
  },
  imgGradient: {
    position: 'absolute',
    left: 0,
    top: IMAGE_HEIGHT / 2,
    width: '100%',
    height: IMAGE_HEIGHT / 2,
    background: 'linear-gradient(180deg, #12152A 0%, rgba(18, 21, 42, 0) 100%)',
    transform: 'rotate(-180deg)',
    [theme.breakpoints.down('md')]: {
      height: IMAGE_HEIGHT_MOBILE / 2,
      top: IMAGE_HEIGHT_MOBILE / 2,
    },
  },
  title: {
    '&.MuiTypography-root': {
      fontFamily: 'Montserrat Medium',
    },
  },
}))

export default useStyles
