import React, { memo } from 'react'

import moment from 'moment'
import { Link } from 'react-router-dom'

import 'moment/locale/ru'

import { Grid, Typography, Link as LinkMui } from '@mui/material'

import { FORMAT_DATE } from 'constants/Date'
import useIsBreakpoint from 'hooks/useIsBreakpoint'

import CarouselCard from '../CarouselCard'
import useStyles from './styles'
import { NewsCardProps } from './types'

moment.locale('ru')

const NewsCard: React.FC<NewsCardProps> = ({ news }) => {
  const classes = useStyles()
  const { isMobileTablet } = useIsBreakpoint()

  const padding = isMobileTablet ? 3 : 5

  return (
    <LinkMui
      component={news.isCustom ? Link : 'a'}
      target={news.isCustom ? '_self' : '_blank'}
      rel="noreferrer"
      {...(news.isCustom ? { to: `/news/${news.url}` } : { href: news.link })}
    >
      <CarouselCard padding={0}>
        <Grid
          classes={{ root: classes.container }}
          direction="column"
          justifyContent="space-between"
          container
        >
          <div className={classes.imgGradient} />
          <img src={news.linkImage} alt={news.title} className={classes.img} />
          <Grid
            direction="column"
            pl={padding}
            pr={padding}
            pb={padding}
            container
          >
            <Typography
              variant="body1"
              mb={2}
              classes={{ root: classes.title }}
            >
              {news.title}
            </Typography>
            <Typography variant="body2">
              {moment(news.createdAt).format(FORMAT_DATE)}
            </Typography>
          </Grid>
        </Grid>
      </CarouselCard>
    </LinkMui>
  )
}

export default memo(NewsCard)
